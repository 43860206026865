import React from 'react'
import { Helmet } from 'react-helmet'
import { Alert, Col, Layout, Row } from 'antd'

const Closed = () => {
  return (
    <div>
      <Layout>
        <Helmet title="Deklaracje za 2024" />
        <Row justify="space-around" align="middle" className="mt-5">
          <Col xs={22} sm={20} md={16} lg={12} xl={8}>
            <Alert
              message="Informacja"
              description="Obsługa deklaracji za rok 2024 została zakończona."
              type="warning"
              showIcon
            />
          </Col>
        </Row>
      </Layout>
    </div>
  )
}

export default Closed
